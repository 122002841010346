/**
 * 用户侧支付相关 API
 */
import { fetch, post } from 'api/base/axios'

const URL = {
  pullUpUrl: '/pay/pull_up/',
  queryPaymentUrl: '/pay/query/',
  prepareUrl: '/pay/prepare/',
  reselectPrepareUrl: '/pay/reselect/prepare/',
  confirmMixedUrl: '/pay/mixed_confirm/',
};

export function pullUpPayment(form) {
  return post(URL.pullUpUrl, form)
}

export function queryPayment(payment_uuid) {
  return post(URL.queryPaymentUrl, {
    'payment_uuid': payment_uuid
  })
}

export function preparePayment(order_uuid_list) {
  return post(URL.prepareUrl, {
    'order_uuids': order_uuid_list
  })
}

export function reselectPreparePayment(payment_uuid) {
  return post(URL.reselectPrepareUrl, {
    'payment_uuid': payment_uuid
  })
}


export function confirmMixedPayment(confirm_key) {
  return fetch(URL.confirmMixedUrl, {
    'confirm_key': confirm_key
  })
}
